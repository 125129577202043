import React from "react";
import { TextField } from "formik-material-ui";

const TextArrayField = props => {
  const { name } = props.field;
  // console.log(props);

  const handleChange = e => {
    // console.log(e);
    let val = typeof e === "string" ? e : e.target.value;
    props.form.setValues({ ...props.form.values, [name]: val.split(",") });
  };

  let newFieldProps = { ...props.field, onChange: handleChange };

  return (
    <TextField
      {...props}
      field={newFieldProps}
      helperText={
        (props.helperText ? props.helperText : "") +
        "Separate multiple values with comma(,)"
      }
    />
  );
};

export default TextArrayField;
