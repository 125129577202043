import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "../redux/reducers";
import { Router } from "react-router-dom";
import browserHistory from "./browserHistory";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const theme = createMuiTheme({
  palette: {
    primary: {
        light: '#ff5c51',
        main: '#D42027',
        dark: '#9a0000',
        contrastText: '#fff'
    }
}
});
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);

const withRoot = Wrapped => props => (
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <Router history={browserHistory}>
        <Wrapped {...props} />
      </Router>
    </Provider>
  </MuiThemeProvider>
);

export default withRoot;
