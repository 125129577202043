import config from "../../config";
import history from "../../components/browserHistory";

export const SERVICELOGIN_QUERY = "SERVICELOGIN_QUERY";
export const SERVICELOGIN_LOGIN = "SERVICELOGIN_LOGIN";
export const SERVICELOGIN_LOGOUT = "SERVICELOGIN_LOGOUT";


const SERVICELOGIN_AUTH_API = "/servicelogin/auth";

const SERVICELOGIN_AUTH_API_FULLPATH = new URL(
  SERVICELOGIN_AUTH_API,
  config.apiBase
).toString();


export const query = async (dispatch, getState) => {
  let serviceLogin = getState();
  if (serviceLogin.status !== "pending") {
    dispatch({
      type: SERVICELOGIN_QUERY,
      status: "init"
    });

    try {
      let currQS = new URLSearchParams(window.location.search);
      let fid = currQS.get("fid");

      let fetchURL = new URL(SERVICELOGIN_AUTH_API_FULLPATH);

      // append fid to login request if fid is in searchParams
      if (fid) fetchURL.searchParams.set("fid", fid);

      let response = await fetch(fetchURL, {
        credentials: "include"
      });
      let result = await response.json();

      if (!response.ok) throw new Error(result);

      dispatch({
        type: SERVICELOGIN_QUERY,
        status: "success",
        payload: result
      });

      if (fid) {
        // clear fid from queryString on successful login
        currQS.delete("fid");
        history.push(`${window.location.pathname}?${currQS.toString()}`);
      }

    } catch (error) {
      dispatch({
        type: SERVICELOGIN_QUERY,
        status: "error",
        payload: error
      });
    }
  }
};

export const login = async (dispatch, getState) => {
  let serviceLogin = getState();
  if (serviceLogin.status !== "pending") {
    dispatch({
      type: SERVICELOGIN_LOGIN,
      status: "init"
    });

    let currQS = new URLSearchParams(window.location.search);
    let fid = currQS.get("fid");
    let fetchURL = new URL(SERVICELOGIN_AUTH_API_FULLPATH);

    try {

      // append fid to login request if fid is in searchParams
      if (fid) fetchURL.searchParams.set("fid", fid);

      let response = await fetch(fetchURL, {
        method: "POST",
        credentials: "include"
      });

      let result = await response.json();
      console.log(result);
      if (result.result_code === 401 && result.data.continue) {
        dispatch({
          type: SERVICELOGIN_LOGIN,
          status: "continue",
          payload: result.data.sl
        });

        window.location.href = result.data.continue;
        return;
      }
      if (response.result_code) throw new Error(response.result_message);
      if (response.error_code) throw new Error(response.error_message);
      dispatch({
        type: SERVICELOGIN_LOGIN,
        status: "success",
        payload: result.data.sl
      });

    } catch (error) {
      dispatch({
        type: SERVICELOGIN_LOGIN,
        status: "error",
        payload: error
      });
    }
    finally {
      if (fid) {
        // clear fid from queryString on successful login
        currQS.delete("fid");
        history.push(`${window.location.pathname}?${currQS.toString()}`);
      }
    }
  }
};

export const logout = async (dispatch, getState) => {
  let serviceLogin = getState();
  if (serviceLogin.status !== "pending") {
    dispatch({
      type: SERVICELOGIN_LOGOUT,
      status: "init"
    });
    try {
      let response = await fetch(SERVICELOGIN_AUTH_API_FULLPATH, {
        method: "DELETE",
        credentials: "include"
      });
      let result = await response.json();
      console.log('Logout result:',result);
      if (response.result_code) throw new Error(response.result_message);
      if (response.error_code) throw new Error(response.error_message);
      
      // dispatch({
      //   type: SERVICELOGIN_LOGOUT,
      //   status: "success",
      //   payload: result.data.sl
      // });

      window.location.href = result.data.continue;
      return;

    } catch (error) {
      dispatch({
        type: SERVICELOGIN_LOGOUT,
        status: "error",
        payload: error.message
      });
    }
  }
};
