import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";
import css from "./style.scss";
import { connect } from "react-redux";

import { get } from "lodash-es";
import { login, logout } from "../../redux/actions/serviceLogin";
import { Switch, Route, Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import TerminalsRoute from "../../routes/terminals";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

const drawerWidth = 240;

export const AppContext = React.createContext({});

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  grow: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    height: "100vh",
    position: "relative",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    flex: 1,
    padding: theme.spacing.unit * 3,
    overflow: "auto"
  }
});

class App extends React.Component {
  state = {
    drawerOpen: false,
    snackBar: {
      open: false,
      message: "",
      timeout: 3000
    }
  };

  showSnackBar = ({ message = "Notice", timeout = 3000 }) => {
    console.log(message);
    console.log(this);
    this.setState({
      snackBar: {
        ...this.state.snackBar,
        message,
        timeout,
        open: true
      }
    });
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ drawerOpen: !state.drawerOpen }));
  };

  handleLogOutButtonClicked = e => {
    const { dispatch } = this.props;
    dispatch(logout);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(login);
  }

  render() {
    const { classes, theme, serviceLogin } = this.props;
    // TODO: remove data stub
    const permissions = {
      terminals: "rw",
      nets: ""
    };
    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        {Object.entries(permissions)
          .filter(([k, v]) => typeof v === "string" && v.includes("r"))
          .map(([k, v]) => (
            <ListItem component="a" href={`/${k}`} key={k}>
              <ListItemIcon>
                <Icon>view_list</Icon>
              </ListItemIcon>
              <ListItemText primary={k} />
            </ListItem>
          ))}
        <ListItem button onClick={this.handleLogOutButtonClicked}>
          <ListItemIcon>
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
        <Divider />
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Icon>menu</Icon>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              noWrap
              className={classes.grow}
            >
              FOMO POS Config
            </Typography>
            <Typography variant="subheading" color="inherit" noWrap>
              {get(this.props, "serviceLogin.userinfo.username") ||
                get(this.props, "serviceLogin.userinfo.email", "...")}
            </Typography>
            <IconButton onClick={this.handleMenu} color="inherit">
              <Icon>account_circle</Icon>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={this.state.drawerOpen}
          onClose={this.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div className={classes.mainContent}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/terminals"/> }/>
              <Route path="/terminals" component={TerminalsRoute} />
            </Switch>
          </div>
        </main>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={this.state.snackBar.open}
          autoHideDuration={this.state.snackBar.timeout}
          onClose={() =>
            this.setState({ snackBar: { ...this.state.snackBar, open: false } })
          }
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{this.state.snackBar.message}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  serviceLogin: state.serviceLogin
});

export default withRoot(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(App))
);
