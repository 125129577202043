import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { Switch } from "formik-material-ui";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const SelectorControl = (props) => {
  const {
    field,
    form,
    label,
    disabled = false,
  } = props;
  const { name } = field;
  const { touched, errors, isSubmitting } = form;
  const propDisabled = isSubmitting || disabled;
  const error = touched[name] && errors[name];

  // console.log(props);

  const handleChange = e => {
    // console.log(e);
    form.setValues({ ...props.form.values, [name]: !props.field.value });
  };

  return (
    <FormControl disabled={propDisabled} error={!!error}>
      <FormLabel>
        {label}
      </FormLabel>
      <Switch
        {...props}
        field={{ ...field, onClick:handleChange }}
        
        checked={!!field.value}
        // value={field.value}
      />
      <FormHelperText >
        {error}
      </FormHelperText>
    </FormControl>
  );
};

export default SelectorControl;
