import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
// import TextField from "@material-ui/core/TextField";
import { TextField, Switch } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TerminalYupModel from "../../models/terminals";
import SelectorControl from "../../components/SelectorControl";
import TextArrayField from "../../components/TextArrayField";
import { get } from "lodash-es";
import config from "../../config";
import { AppContext } from "../../components/App";

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex"
  }
});

const modelToFieldProps = model => {
  let props = {
    type: "text",
    component: TextField
  };

  // console.log(props);

  switch (model.type) {
    case "boolean":
      return {
        ...props,
        component: SelectorControl
      };
    case "number":
      return {
        ...props,
        type: "number"
      };
    case "array":
      return {
        ...props,
        component: TextArrayField
      };
    case "string":
    default:
      return props;
  }
};

// generate empty values object from yup models
const initValues = Object.entries(TerminalYupModel.describe().fields).reduce(
  (prev, [k, v]) => ({ [k]: "", ...prev }),
  {}
);

class TerminalEditPage extends React.Component {
  state = {
    data: initValues
  };

  fetchData = async () => {
    const id = this.props.match.params.id;
    let res = await fetch(
      new URL(`/webapi/configuration/${id}`, config.apiBase),
      { credentials: "include" }
    );
    let resData = await res.json();
    // let resData = {
    //   id: "1776436978db93cab44aa4bc5afd224e",
    //   mid: "100000000000001",
    //   tid: "00000001",
    //   auto_settlement: true,
    //   settlement_period: 1,
    //   xchange_rate_print: true,
    //   xchange_rate_display: true,
    //   amt_upper_limit: null,
    //   amt_lower_limit: null,
    //   support_currency: "SGD",
    //   support_payment_code: [21, 22],
    //   version: 33,
    //   amt_exchange_print: true,
    //   amt_exchange_display: true
    // };
    this.setState({ data: resData });
  };

  pushData = async data => {
    const id = this.props.match.params.id;
    let res = await fetch(
      new URL(`/webapi/configuration/${id}`, config.apiBase),
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify(data)
      }
    );
    let resData = await res.json();
    // this.formik.setValues(resData);
  };

  handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(JSON.stringify(values, null, 2));
      await this.pushData(values);
      this.props.appContext.showSnackBar({ message: "Submission Success" });
    } catch (err) {
    } finally {
      setSubmitting(false);
    }
  };

  componentDidMount = () => {
    this.fetchData();
  };

  render() {
    const { classes } = this.props;

    const TerminalModel = TerminalYupModel.describe();
    let fields = Object.entries(TerminalModel.fields).map(([name, model]) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={name}>
        <Field
          label={model.label}
          name={name}
          disabled={get(model, "meta.readonly")}
          helperText={get(model, "meta.description")}
          {...modelToFieldProps(model)}
        />
      </Grid>
    ));

    return (
      <Formik
        initialValues={this.state.data}
        ref={elem => (this.formik = elem)}
        validationSchema={TerminalYupModel}
        enableReinitialize
        onSubmit={this.handleSubmit}
      >
        {({ isSubmitting, ...props }) => (
          <Form>
            <Grid container spacing={24}>
              {fields}
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={props.submitForm}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withStyles(styles)(
  React.forwardRef((props, ref) => (
    <AppContext.Consumer>
      {ctx => <TerminalEditPage {...props} appContext={ctx} ref={ref} />}
    </AppContext.Consumer>
  ))
);
