import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";

import TerminalsYupModel from "../../models/terminals";

import { debounce } from "lodash-es";

import config from "../../config";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const TerminalsModel = TerminalsYupModel.describe();

// console.log(TerminalsModel);

const objectEntriesKeyComparator = ([lk], [rk]) =>
  lk > rk ? 1 : lk < rk ? -1 : 0;

class TerminalsPage extends React.Component {
  state = {
    data: null,
    sort: ["mid", "ASC"],
    filter: {},
    page: 0,
    rowsPerPage: 5,
    dataCount: 0
  };

  fetchData = async () => {
    let { sort, filter, page, rowsPerPage } = this.state;
    let range = [page * rowsPerPage, (page + 1) * rowsPerPage - 1];
    let fetchURL = new URL("/webapi/configuration", config.apiBase);
    fetchURL.searchParams.set("sort", JSON.stringify(sort));
    fetchURL.searchParams.set("filter", JSON.stringify(filter));
    fetchURL.searchParams.set("range", JSON.stringify(range));
    let res = await fetch(fetchURL, { credentials: "include" });
    let contentRange = res.headers.get("content-range");
    let dataCount = parseInt(contentRange.split("/").pop());
    let resData = await res.json();
    // let resData = [
    //   {
    //     id: 3,
    //     mid: "100000000000001",
    //     tid: "00000001",
    //     auto_settlement: true,
    //     settlement_period: 1,
    //     xchange_rate_print: true,
    //     xchange_rate_display: true,
    //     amt_upper_limit: null,
    //     amt_lower_limit: null,
    //     support_currency: "SGD",
    //     support_payment_code: [21],
    //     version: 33,
    //     amt_exchange_print: true,
    //     amt_exchange_display: true
    //   },
    //   {
    //     id: 4,
    //     mid: "100000000000001",
    //     tid: "00000002",
    //     auto_settlement: true,
    //     settlement_period: 1,
    //     xchange_rate_print: false,
    //     xchange_rate_display: false,
    //     amt_upper_limit: null,
    //     amt_lower_limit: null,
    //     support_currency: "SGD",
    //     support_payment_code: [21],
    //     version: 6,
    //     amt_exchange_print: false,
    //     amt_exchange_display: false
    //   }
    // ];
    this.setState({ data: resData, dataCount });
  };

  fetchDataDebounced = debounce(this.fetchData, 1000);

  handleFilterInput = key => event => {
    let value = event.target.value || undefined;
    this.setState({ filter: { ...this.state.filter, [key]: value } });
    this.fetchDataDebounced();
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.fetchData();
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    this.fetchData();
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.page !== this.state.page ||
      prevState.rowsPerPage !== this.state.rowsPerPage
    ) {
      this.fetchData();
    }
  }

  render() {
    const { classes } = this.props;
    const { data, rowsPerPage, page, dataCount } = this.state;

    const iconYes = <Icon>check</Icon>;
    const iconNo = <Icon>close</Icon>;

    const sortedEntries = Object.entries(TerminalsModel.fields)
      .filter(([key]) => !["mid", "tid", "id"].includes(key))
      .sort(objectEntriesKeyComparator);

    const renderTableCell = (value, info) => {
      switch (info.type) {
        case "boolean":
          return value ? iconYes : iconNo;
        case "array":
          return Array.isArray(value) && value.join(",");
        case "object":
        case "number":
        case "string":
        default:
          return value;
      }
    };

    return (
      <div>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="MID"
              type="search"
              // margin="normal"
              variant="outlined"
              value={this.state.filter.mid || ""}
              onInput={this.handleFilterInput("mid")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label="TID"
              type="search"
              // margin="normal"
              variant="outlined"
              value={this.state.filter.tid || ""}
              onInput={this.handleFilterInput("tid")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>MID</TableCell>
                <TableCell>TID</TableCell>
                {sortedEntries.map(([key, value]) => (
                  <TableCell key={key} numeric={value.type === "number"}>
                    {value.label || key}
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) ? (
                data.map(row => (
                  <TableRow key={row.id}>
                    {/* <TableCell numeric>{row.id}</TableCell> */}
                    <TableCell>{row.mid}</TableCell>
                    <TableCell>{row.tid}</TableCell>
                    {sortedEntries.map(([key, col]) => (
                      <TableCell key={key} numeric={col.type === "number"}>
                        {renderTableCell(row[key], col)}
                      </TableCell>
                    ))}
                    <TableCell>
                      <IconButton
                        color="primary"
                        href={`/terminals/edit/${row.id}`}
                        className={classes.button}
                        component="a"
                      >
                        <Icon>edit</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={14}>Loading...</TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={dataCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(TerminalsPage);
