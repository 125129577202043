// polyfills
// import 'core-js'; // general ES functions
// import 'raf/polyfill'; // requestAnimationFrame()
// import 'url-polyfill'; // URL()
// import 'whatwg-fetch'; // fetch()
// global fixes
import 'normalize.css';

import React from "react";
import ReactDOM from "react-dom";
import App from './components/App';
import './styles/index.scss';
import * as serviceWorker from './serviceWorker';

let mountNode = document.getElementById('root');
ReactDOM.render(<App />, mountNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();