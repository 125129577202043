import React from "react";
import { Route, Switch } from "react-router-dom";
import TerminalsEditPage from "./edit";
import TerminalsListPage from "./list";

const TerminalsRoute = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/edit/:id`} exact component={TerminalsEditPage} />
    <Route path={`${match.path}/`} exact component={TerminalsListPage} />
  </Switch>
)

export default TerminalsRoute;
