import * as yup from 'yup';

const TerminalsYupModel = yup.object().shape({
  id: yup.string().required().label('ID').meta({readonly: true}),
  mid: yup.string().required().label('MID').meta({readonly:true}),
  tid: yup.string().required().label('TID').meta({readonly:true}),
  device_id: yup.string().required().label('Device ID').meta({readonly:true}),
  settlement_period: yup.number().label("Settlement Period").nullable(true),
  auto_settlement:yup.boolean().label('Auto Settlement').nullable(true),
  support_currency:yup.string().label("Supported Currency").nullable(true),
  support_payment_code: yup.array(yup.number()).label('Supported Payment Codes'),
  xchange_rate_display: yup.boolean().label('Display Exchange Rate').nullable(true),
  xchange_rate_print: yup.boolean().label('Print Exchange Rate').nullable(true),
  amt_exchange_display: yup.boolean().label('Display Exchange Amount').nullable(true),
  amt_exchange_print: yup.boolean().label('Print Exchange Amount').nullable(true),
  amt_upper_limit: yup.number().label('Upper Amount Limit').nullable(true),
  amt_lower_limit: yup.number().label('Lower Amount Limit').nullable(true),
  version: yup.number().integer().label('Version').meta({readonly:true}).nullable(true)
});

// console.log(TerminalsYupModel.describe());

export default TerminalsYupModel;