import * as actions from '../actions/serviceLogin';
import {merge} from 'lodash-es';

const initState = {
    userinfo:{},
    status: 'init'
}

function reduceSLQuery(state, action){
    switch(action.status){
        case 'init':
            return {...state, status: 'pending'};
        case 'success':
            return {...state, status: 'success', userinfo: action.payload.userinfo};
        case 'error':
            return {...state, status: 'error', error: action.payload};
        default:
            return state;
    }
}

function reduceSLLogin(state, action){
    switch(action.status){
        case 'init':
            return {...state, status: 'pending'};
        case 'success':
            return {...state, status: 'success', userinfo: action.payload.userinfo};
        case 'error':
            return {...state, status: 'error', error: action.payload};
        default:
            return state;
    }
}

function reduceSLLogout(state, action){
    switch(action.status){
        case 'init':
            return {...state, status: 'pending'};
        case 'success':
            return {...state, status: 'success', userinfo: action.payload.userinfo};
        case 'error':
            return {...state, status: 'error', error: action.payload};
        case 'redir':
            return {...state, status: 'redir'}
        default:
            return state;
    }
}

export default function(state=initState,action){
    switch(action.type){
        case actions.SERVICELOGIN_QUERY:
            return reduceSLQuery(state, action);
        case actions.SERVICELOGIN_LOGIN:
            return reduceSLLogin(state, action);
        case actions.SERVICELOGIN_LOGOUT:
            return reduceSLLogout(state, action);
        default:
            return state;
    }
}